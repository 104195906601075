import "./HomeClosed.css";
import React from "react";
import ModernHousePNG from "../../images/modern-house.png";

export default function HomeClosed() {
    return (
        <>
            <div>
                <header className="header">
                    <div className="header-overlay">
                        <div className="container-sm text-center mt-5">
                            <img className="" style={{width: '250px'}} src={ModernHousePNG} alt="image"/>
                            <div style={{fontSize: '38px'}}>Gülbahçe Villa</div>
                            <div>İlanımız şu anda aktif değildir</div>
                            <div>Ziyaretiniz için teşekkürler</div>
                        </div>
                    </div>
                </header>

            </div>
        </>
    );
}
