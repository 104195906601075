import './App.css';
import {Route, Routes} from 'react-router-dom';
import HomeClosed from "./components/placeholder/HomeClosed";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomeClosed/>}/>
            </Routes>
        </div>
    );
}

export default App;
